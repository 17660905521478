// import store from '../store'
import axios from 'axios'
import {
  message
} from 'ant-design-vue'
const service = axios.create({
  baseURL: 'https://sapi.easyebid.com', // 正式
  //baseURL: 'https://sapi.test.easyebid.com', // 测试
  //baseURL: 'https://tsapi.easyebid.com', // 预发环境
  timeout: 150000,
  headers: {
    Userid: localStorage.getItem('accessId') || 0
  }
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const {
      data
    } = response
    if (data.code === 401) {
      message.error(data.msg)
      localStorage.clear()
      // var timer = setInterval(() => {
      //   location.reload()
      //   clearInterval(timer)
      // }, 1000)
    }
    if (response.status === 200) {
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
